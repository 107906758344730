import React, { useEffect, useState, useContext } from 'react';
import ReactEditor from 'components/editor/editor';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import firebase from '../firebase/base';
import { AuthContext } from '../firebase/auth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newCard: {
      background: '#fff',
      borderRadius: 8,
      margin: '20px',
    },
    titleContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      height: 100,
      alignItems: 'center',
      justifyContent: 'center',
    },
    input: {
      width: 250,
    },
    button: {
      width: `${100}%`,
      borderRadius: 8,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      padding: 10,
    },
  })
);

interface INewCardForm {
  title: string;
  content: string;
}

const NewCard = ({
  submitCallback,
  isInspiration,
  boardId,
}: {
  submitCallback: () => void;
  isInspiration: boolean;
  boardId: string;
}) => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const createCard = ({ title, content }: INewCardForm) => {
    const newCard = {
      title,
      isInspiration,
      text: content,
      author: currentUser.name,
      authorId: currentUser.uid,
    };
    setLoading(true);
    const response = firebase.addCard(newCard, boardId);
    response.then(res => {
      console.log(res);
      // fetchData();
    });
  };

  useEffect(() => {
    if (loading) {
      document.body.style.cursor = 'wait';
    } else {
      document.body.style.cursor = 'default';
    }
  }, [loading]);

  return (
    <div className={classes.newCard}>
      <Formik
        initialValues={{ title: '', content: '<p></p>\n' }}
        validate={values => {
          const errors = {} as { title: string; content: string };
          if (!values.title) {
            errors.title = 'Required';
          }
          if (values.content === '<p></p>\n') {
            errors.content = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          createCard(values);
          setSubmitting(false);
          setLoading(false);
          submitCallback();
        }}
      >
        {({ isSubmitting, errors, values, setValues, touched, setTouched }) => (
          <Form>
            <div className={classes.titleContainer}>
              <FormControl error={errors.title !== undefined}>
                <Field
                  component={TextField}
                  type='text'
                  name='title'
                  className={classes.input}
                  placeholder='Title'
                  inputProps={{
                    'aria-label': 'title',
                  }}
                />
              </FormControl>
            </div>
            <ReactEditor
              htmlStateCallback={content => {
                const newValues = { ...values };
                newValues.content = content;
                if (touched.content) {
                  setValues(newValues);
                }
              }}
              editorProps={{
                placeholder: 'Content',
                onFocus: () => {
                  console.log(touched);
                  touched.content = true;
                  setTouched({ ...touched });
                },
              }}
              contentError={errors.content}
            />
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.button}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewCard;
