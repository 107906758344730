import React, { useEffect, useState } from 'react';
import firebase from './base';

export const AuthContext = React.createContext({} as { currentUser: firebase.User | null });

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [currentUser, setCurrentUser] = useState(null as firebase.User | null);
  useEffect(() => {
    firebase.onAuthUserListener(setCurrentUser);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
