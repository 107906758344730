import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import firebase from '../firebase/base';
import Signup from 'components/Signup';
import background from '../assets/login_background.jpg';
import placeholder from '../assets/login_background_small.jpg';
import BackgroundOverlayContainer from '../components/BackgroundOverlayContainer';

const SignupPage = ({ history }: { history: any }): JSX.Element => {
  const handleSignUp = useCallback(
    async event => {
      event.preventDefault();
      const { email, password, name } = event.target.elements;
      try {
        await firebase.auth
          .createUserWithEmailAndPassword(email.value, password.value)
          .then(authUser => {
            return firebase.getUser(authUser.user.uid).set({
              name: name.value,
              email: email.value,
            });
          });
        history.push('/');
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  return (
    <BackgroundOverlayContainer background={background} placeholder={placeholder}>
      <Signup onSubmit={handleSignUp} />
    </BackgroundOverlayContainer>
  );
};

export default withRouter(SignupPage);
