import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';

export const htmlToDraftEditorState = (htmlString: string) => {
  const contentBlock = htmlToDraft(htmlString);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  console.error('htmlToDraftEditorState() failed to create the editor state. Returning empty state instead');
  return EditorState.createEmpty();
};

export const draftEditorStateToHtml = (editorState: EditorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};
