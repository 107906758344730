import { ICard } from '../interfaces/ICard';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import IUser from '../interfaces/IUser';
import { IBoard } from '../interfaces/IBoard';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

export enum CollectionType {
  boards = 'boards',
  cards = 'cards',
  users = 'users',
}

class Firebase {
  public auth: firebase.auth.Auth;
  public db: firebase.firestore.Firestore;
  public user: firebase.User | undefined;

  constructor() {
    const app = firebase.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();

    this.onAuthUserListener((user: firebase.User) => {
      this.user = user;
      return this.user;
    });
  }

  private getCollections(): { [collectionName: string]: firebase.firestore.CollectionReference } {
    return {
      boards: this.db.collection(CollectionType.boards),
      users: this.db.collection(CollectionType.users),
      // cards: this.db.collection(CollectionType.cards),
    };
  }

  public onAuthUserListener(next: (user: firebase.User & IUser) => void, fallback = () => {}) {
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.getUser(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            // default empty roles
            if (dbUser && !dbUser.roles) {
              dbUser.roles = {};
            }
            // merge auth and db user
            const newAuthUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
            next(newAuthUser);
          });
      } else {
        next(authUser);
      }
    });
  }

  public getBoards(): Promise<IBoard[]> {
    const boards$ = this.getCollections()
      .boards.orderBy('created', 'desc')
      .get();
    return boards$.then((snapshot: firebase.firestore.QuerySnapshot) =>
      snapshot.docs.map((s: firebase.firestore.QueryDocumentSnapshot) => {
        const c = s.data() as IBoard;
        return {
          ...c,
          uid: s.id,
        };
      })
    );
  }

  public async getBoard(id: string) {
    console.log({ id });
    const board = await this.getCollections().boards.doc(id);
    // const data = boards.data();
    return board;
  }

  public async getCards(boardId: string): Promise<ICard[]> {
    console.log({ boardId });
    const board = await this.getBoard(boardId);
    const cards$ = board.collection(CollectionType.cards).get();
    return cards$.then((snapshot: firebase.firestore.QuerySnapshot) =>
      snapshot.docs.map((s: firebase.firestore.QueryDocumentSnapshot) => {
        const c = s.data() as ICard;
        return {
          ...c,
          uid: s.id,
        };
      })
    );
  }

  public async addBoard(): Promise<void> {
    return this.getCollections()
      .boards.add({
        created: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(boardRef => {
        boardRef.collection(CollectionType.cards);
        console.log('Board created:', boardRef);
      })
      .catch(error => {
        console.error('Error adding document: ', error);
      });
  }

  public async addCard(card: ICard, boardId: string): Promise<void> {
    const board = await this.getBoard(boardId);

    return board
      .collection(CollectionType.cards)
      .add(card)
      .then(cardRef => {
        console.log('BoardId', boardId);
        console.log('Card created ', cardRef);
      })
      .catch(error => console.error(error));
  }

  public async deleteCard(cardUid: string, boardId: string) {
    const board = await this.getBoard(boardId);
    return board
      .collection(CollectionType.cards)
      .doc(cardUid)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch(error => {
        console.error('Error removing document: ', error);
      });
  }

  public addUser(user: IUser): Promise<firebase.firestore.DocumentReference> {
    return this.getCollections().users.add(user);
  }

  public getUser(uid: string): firebase.firestore.DocumentReference {
    return this.db.doc(`users/${uid}`);
  }
}

export default new Firebase();
