import React, { forwardRef, useImperativeHandle } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

interface IProps {
  open: boolean;
  children: React.ReactElement;
}

const TransitionsModal = forwardRef<void, IProps>((props, ref) => {
  const { open, children } = props;
  const classes = useStyles();
  const [stateOpen, setOpen] = React.useState(open);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
    handleClose() {
      setOpen(false);
    },
    toggle() {
      setOpen(!stateOpen);
    },
  }));

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={stateOpen}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {children && <Fade in={stateOpen}>{children}</Fade>}
      </Modal>
    </div>
  );
});

export default TransitionsModal;
