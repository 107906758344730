import React, { useCallback, useContext } from 'react';
import { withRouter, Redirect } from 'react-router';
import firebase from '../firebase/base';
import { AuthContext } from '../firebase/auth';
import background from '../assets/login_background.jpg';
import placeholder from '../assets/login_background_small.jpg';
import Login from 'components/Login';
import BackgroundOverlayContainer from '../components/BackgroundOverlayContainer';

const LoginPage = ({ history }: { history: any }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await firebase.auth.signInWithEmailAndPassword(email.value, password.value);
        history.push('/');
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to='/' />;
  }

  return (
    <BackgroundOverlayContainer background={background} placeholder={placeholder}>
      <Login onSubmit={handleLogin} />
    </BackgroundOverlayContainer>
  );
};

export default withRouter(LoginPage);
