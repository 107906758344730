import React, { useEffect, useState, useContext, useRef } from 'react';
import Macy from 'macy';
import { ICard } from '../interfaces/ICard';
import { Box, IconButton } from '@material-ui/core';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '../components/Card';
import Modal from 'components/Modal';
import NewCard from 'components/NewCard';
import firebase from '../firebase/base';
import { AuthContext } from '../firebase/auth';
import { getLogoPath } from 'components/Logo';
import particles, { sandpit } from '../components/particles';
import './styles/EditorOverwrites.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 90,
      paddingBottom: 80,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    item: {
      marginBottom: `${2}em`,
      marginRight: `${0}em`,
    },
    addButton: {
      // height: 'min-content',
    },
    overlay: {
      position: 'fixed',
      height: `${100}%`,
      width: `${100}%`,
      margin: `${-2}%`,
      opacity: 0.5,
      backgroundImage: `url('${getLogoPath('white')}')`,
      backgroundPositionX: 'right',
      backgroundPositionY: 'bottom',
      backgroundRepeat: 'no-repeat',
    },
    inspirationContainer: {
      border: '3px solid gold',
      borderRadius: 2,
    },
  })
);

const Board = ({
  cards,
  handleDeleteClick,
  refetchData,
  loading,
  boardId,
}: {
  cards: ICard[];
  handleDeleteClick: (uid: string) => void;
  refetchData: () => void;
  loading: boolean;
  boardId: string;
}): JSX.Element => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const modalRef = useRef();

  const ActionsComponent = ({
    uid,
    authorUid,
  }: {
    uid: string | undefined;
    authorUid: string | undefined;
  }) => (
    <Box>
      <IconButton
        aria-label='delete'
        disabled={authorUid === currentUser.uid ? false : true}
        color='primary'
        onClick={() => handleDeleteClick(uid)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );

  const renderCard = (card: ICard, key: number) => (
    <div className={card.isInspiration ? classes.inspirationContainer : ''} key={key}>
      <Card
        title={card.title}
        text={card.text}
        author={card.author}
        contentMaxHeight={500}
        actionsComponent={<ActionsComponent uid={card.uid} authorUid={card.authorId} />}
      />
    </div>
  );

  const inspirationCardKey = cards.findIndex(card => card.isInspiration);
  const inspirationExists = inspirationCardKey !== -1;

  return (
    <>
      <div id='root' />
      <div className={classes.overlay} />
      <CssBaseline />
      <Modal ref={modalRef} open={false}>
        <NewCard
          submitCallback={() => {
            modalRef.current.handleClose();
            refetchData();
          }}
          isInspiration={!cards.length ? true : false}
          boardId={boardId}
        />
      </Modal>
      <div className={classes.contentContainer}>
        {loading ? null : (
          <div id='macyContainer'>
            {inspirationExists ? renderCard(cards[inspirationCardKey], inspirationCardKey) : null}
            {cards.map((card, key) => {
              if (key === inspirationCardKey) {
                return;
              }
              return renderCard(card, key);
            })}
            <div className={classes.addButton} onClick={() => modalRef.current.handleOpen()}>
              <Card title={'+ Add New'} modalDisabled={true} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Board;
