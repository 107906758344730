import React from 'react';
import { Router } from 'react-router';
import { Route, BrowserRouter } from 'react-router-dom';
import Login from './pages/Login';
import SignUp from './pages/Signup';
import PrivateRoute from './pages/PrivateRoute';
import Board from './pages/Board';
import { AuthProvider } from './firebase/auth';
import './App.css';
import 'draft-js/dist/Draft.css';

const App = (): JSX.Element => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <PrivateRoute exact path='/' component={Board} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/signup' component={SignUp} />
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
