import React, { useState, useEffect } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { draftEditorStateToHtml } from '../../utils/draftjsAndHtml';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormHelperText } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import toolbarConfig from './toolbarConfig';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentErrorHelper: {
      position: 'relative',
      left: 20,
      top: -15,
      color: 'red',
    },
  })
);

const ReactEditor = ({
  htmlStateCallback,
  editorProps,
  contentError,
}: {
  htmlStateCallback: (editorStateHtml: string) => void;
  editorProps: object;
  contentError: string | undefined;
}) => {
  const classes = useStyles();

  const [state, setState] = useState(EditorState.createEmpty());
  const onChange = (editorState: any) => setState(editorState);

  useEffect(() => {
    htmlStateCallback(draftEditorStateToHtml(state));
  }, [state]);

  return (
    <>
      <Editor
        editorState={state}
        wrapperClassName='editor-wrapper'
        editorClassName={contentError ? 'editor-content-error' : 'editor-content'}
        toolbarClassName='editor-toolbar'
        onEditorStateChange={onChange}
        toolbar={toolbarConfig}
        {...editorProps}
      />
      {contentError ? (
        <FormHelperText className={classes.contentErrorHelper}>{contentError}</FormHelperText>
      ) : null}
    </>
  );
};

export default ReactEditor;
