import React, { useCallback, useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ReactImageAppear from 'components/react-image-appear/ReactImageAppear';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: -1,
      width: '100%',
      height: 'auto',
      filter: 'blur(2px)',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        width: 'auto',
      },
    },
    img: {
      maxWidth: '100%',
      maxHeight: 'initial',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '100%',
        maxWidth: 'initial',
      },
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: theme.palette.primary.main,
      opacity: 0.6,
      mixBlendMode: 'luminosity',
    },
  })
);

const BackgroundOverlayContainer = ({
  children,
  background,
  placeholder,
}: {
  children: React.ReactNode;
  background: string;
  placeholder: string;
}) => {
  const classes = useStyles();
  return (
    <div>
      {children}
      <div className={classes.box}>
        <ReactImageAppear
          imgClass={classes.img}
          src={background}
          placeholder={placeholder}
          animation={undefined}
          showLoader={false}
        />
        <div className={classes.overlay} />
      </div>
    </div>
  );
};

export default BackgroundOverlayContainer;
