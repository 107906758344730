import React from 'react';
import logo_white from 'assets/stanza_transparent_white.png';
import logo_red from 'assets/stanza_transparent_red.png';

export enum LogoColor {
  white = 'white',
  red = 'red',
}

export const getLogoPath = (color: LogoColor): string | undefined => {
  switch (color) {
    case LogoColor.white:
      return logo_white;
    case LogoColor.red:
      return logo_red;
    default:
      console.error('The logo color provided is wrong');
      return undefined;
  }
};

const Logo = ({ color, width }: { color: LogoColor; width: number }) => {
  return <img src={getLogoPath(color)} width={width} />;
};

export default Logo;
