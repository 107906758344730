import React, { useState, useEffect, useRef } from 'react';
import Macy from 'macy';
import { ICard } from '../interfaces/ICard';
import Button from '@material-ui/core/Button';
import Board from 'components/Board';
import { FaSignOutAlt } from 'react-icons/fa';
import base from 'firebase/base';
import Drawer from 'components/Drawer';
import particles, { sandpit } from '../components/particles';

const BoardPage = () => {
  const [loading, setLoading] = useState(true);
  const [boardId, setBoardId] = useState('');
  const [cards, setCards] = useState([] as ICard[]);
  const boards = useRef([]);
  const macy = useRef();

  console.log(boards.current);
  console.log(boardId);

  const handleDeleteClick = (uid: string) => {
    setLoading(true);
    const response = base.deleteCard(uid, boardId);
    response.then(res => {
      fetchData();
    });
  };

  async function fetchData() {
    setLoading(true);
    boards.current = await base.getBoards();
    if (boardId === '' && boards.current.length) {
      setBoardId(boards.current[0].uid);
    }

    if (boardId !== undefined && boardId !== '') {
      console.log('fetching cards', boardId);
      const c = await base.getCards(boardId);
      setCards(c);
      setLoading(false);
      macy.current = Macy({
        container: '#macyContainer',
        trueOrder: false,
        waitForImages: false,
        margin: 40,
        columns: 4,
        breakAt: {
          1200: 4,
          940: 3,
          650: 2,
          450: 1,
        },
      });
    } else {
      setLoading(false);
    }
  }

  function onMouseUpdate(e: MouseEvent) {
    sandpit.input.x = e.pageX;
    sandpit.input.y = e.pageY;
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseUpdate, false);
    document.addEventListener('mouseenter', onMouseUpdate, false);
    fetchData();
    particles();

    return () => {
      document.removeEventListener('mousemove', onMouseUpdate, false);
      document.removeEventListener('mouseenter', onMouseUpdate, false);
    };
  }, []);

  useEffect(() => {
    if (macy.current && !loading) {
      macy.current.recalculate();
    }
    if (loading) {
      sandpit.pull = 7;
    } else {
      sandpit.pull = 1;
    }
  }, [loading]);

  useEffect(() => {
    fetchData();
  }, [boardId]);

  return (
    <div>
      <Drawer
        title='Stanza'
        refetchData={fetchData}
        boardIds={boards.current.map(board => board.uid)}
        setBoardId={id => setBoardId(id)}
      >
        <Button color='secondary' startIcon={<FaSignOutAlt />} onClick={() => base.auth.signOut()}>
          Log Out
        </Button>
      </Drawer>
      <Board
        cards={cards}
        handleDeleteClick={handleDeleteClick}
        refetchData={fetchData}
        loading={loading}
        boardId={boardId}
      />
    </div>
  );
};

export default BoardPage;
