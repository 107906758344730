import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialCard from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from 'components/Modal';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    backgroundColor: '#fff',
    borderRadius: 3,
    opacity: 0.9,
  },
  media: {
    height: 140,
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 13,
    paddingRight: 4,
    paddingBottom: 0,
  },
  cardActionRight: {
    marginBottom: 4,
  },
  blob: {
    position: 'absolute',
    opacity: 0.7,
    height: `${50}%`,
    marginTop: `${10}%`,
  },
  cardContentRoot: {
    // padding: '16px',
    overflow: 'hidden',
    // maxHeight: '200px',
    background:
      ' linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,  radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
    backgroundAttachment: 'local, local, scroll, scroll',
  },
  authorButtonContainer: {
    maxWidth: `${70}%`,
    overflow: 'hidden',
  },
  authorButton: {
    justifyContent: 'left',
  },
});

interface IProps {
  title: string;
  text?: string;
  author?: string;
  image?: string;
  contentMaxHeight?: number;
  modalDisabled?: boolean;
  children?: JSX.Element;
  actionsComponent?: React.ReactNode;
}

const MediaCard = (props: IProps): JSX.Element => {
  const { title, text, author, image, contentMaxHeight, actionsComponent, modalDisabled } = props;
  const classes = useStyles();
  const modalRef = useRef();

  let modalOpenToggle = () => modalRef.current.toggle();
  if (modalDisabled) {
    modalOpenToggle = () => {};
  }

  return (
    <div>
      <MaterialCard className={classes.card}>
        <CardActionArea onClick={modalOpenToggle}>
          {image ? (
            <CardMedia className={classes.media} image={image} title='Contemplative Reptile' />
          ) : null}
          <CardContent className={classes.cardContentRoot} style={{ maxHeight: contentMaxHeight }}>
            <Typography gutterBottom variant='h5' component='h2'>
              {title}
            </Typography>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </CardContent>
        </CardActionArea>
        {author ? (
          <CardActions className={classes.cardAction}>
            <div className={classes.authorButtonContainer}>
              <Button className={classes.authorButton} size='small' color='primary'>
                {author}
              </Button>
            </div>
            <div className={classes.cardActionRight}>
              {actionsComponent ? actionsComponent : null}
            </div>
          </CardActions>
        ) : null}
      </MaterialCard>
      {!modalDisabled && (
        <Modal ref={modalRef} open={false}>
          <MaterialCard className={classes.card} style={{ padding: 10 }}>
            <Typography gutterBottom variant='h5' component='h2'>
              {title}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </MaterialCard>
        </Modal>
      )}
    </div>
  );
};

export default MediaCard;
